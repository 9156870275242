import { Menu, MenuItem, MenuDivider, IAction } from 'src/web/components';
import {
  GET_IDENTITY,
  IdentityStatus,
  UPDATE_IDENTITY,
  UpdateIdentityStatus,
  useMutation,
  useQuery,
} from 'src/data';

export interface IdentityPopoverProps {
  userId: string;
  openManualIDP: () => void;
}

const noop = () => {};

export function IdentityPopover({ userId, openManualIDP }) {
  const { loading, data, refetch } = useQuery(GET_IDENTITY, {
    variables: { id: userId },
    skip: !userId,
  });

  const [markNeedsDocs, { loading: updating }] = useMutation(UPDATE_IDENTITY, {
    variables: { input: { personID: userId, status: UpdateIdentityStatus.NeedsDocs } },
  });

  const identity = data?.viewerTwo?.edeIdentity;

  const showNeedsDocs = identity?.status !== IdentityStatus.Verified;
  const showManual = identity?.status === IdentityStatus.PendingManualReview;

  return (
    <Menu
      content={
        <>
          {showNeedsDocs && <MenuItem iconic="Passport" text="Send to doc upload" onPress={markNeedsDocs} />}
          {showManual && <MenuItem iconic="Fingerprint" text="Manual IDP" onPress={openManualIDP} />}
          {(showNeedsDocs || showManual) && <MenuDivider />}
          <MenuItem iconic="Loading" text="Refresh" onPress={() => refetch({ id: userId })} />
        </>
      }
    >
      <div>
        <IAction
          icon="Fingerprint"
          loading={loading}
          subtitle="Identity"
          status={identity?.status}
          onPress={() => {}}
        />
      </div>
    </Menu>
  );
}
