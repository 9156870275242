import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { initials } from 'src/web/utility';
import { EdeSharedInspector } from 'src/web/inspectors';
import { useUserSummary } from 'src/web/hooks';

import { Scroll, Header } from 'src/web/components';
import { HEALTH_INSPECTOR_NEW } from 'src/web/data';
import { InspectorPaneProps } from '../../types';

export function HealthAppPane({ userId, id }: InspectorPaneProps) {
  const { givenName, familyName, avatarImage } = useUserSummary({ id: userId });

  const { data: appData, loading } = useQuery(HEALTH_INSPECTOR_NEW, {
    skip: !id,
    variables: {
      id: userId,
      appId: id,
    },
  });

  const app = {
    ...appData?.viewerTwo?.health?.application,
    ...appData?.viewerTwo?.health?.applicationSummary,
  };

  return (
    <Scroll inspector>
      <Header
        pii
        avatar={avatarImage}
        title="Application"
        initials={initials({
          givenName: givenName,
          familyName: familyName,
        })}
      />
      {!!app && (
        <EdeSharedInspector
          application={app}
          user={{ id: userId }}
          pol={app?.policies?.[0]}
          enrollment={{
            id: app?.enrollment?.id,
            coverageState: app?.coverageState,
            year: app?.coverageYearNumber,
            lastUsedRoute: app?.lastUsedRoute,
            lastUserUpdate: app?.lastUserUpdate,
            ownerId: userId,
            applicationID: app?.id,
            idProofResult: app?.idProofResult,
            applicationOrigin: app?.applicationOrigin,
            pathway: app?.pathwayType,
            status: app?.status,
            eligibilityLastFetched: app?.eligibilityLastFetched,
            isOpenEnrollment: app?.enrollment?.isOpenEnrollment,
          }}
        />
      )}
    </Scroll>
  );
}
