import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { FullModal, RowGroup, IRow, MenuDivider, Spinner } from 'src/web/components';
import { GET_APPLICATION, GET_TRACKING_LOG, TrackingLogFragment } from 'src/data';
import { DebugTree } from 'src/web/inspectors';
import { DateFormat } from 'src/utils';

const StyledTile = styled.div`
  position: absolute;
  right: 24px;
  left: 24px;
  bottom: 200px;
  top: calc(24px + var(--navHeight));
  opacity: 1;
  background: transparent url(${p => p.src}) no-repeat center/contain;
  height: auto;
  width: auto;
  filter: drop-shadow(0px 5px 20px rgba(6, 7, 10, 0.15));
  cursor: pointer;
  transform: rotate(${p => p.rotation}deg);
  will-change: transform;
  transition: var(--transition);
  div {
    will-change: transform;
    transform: rotate(${p => -p.rotation}deg);
    transition: var(--transition);
  }
`;

interface TrackingLogModalProps {
  personId: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

const readableURLs = {
  'https://impl.hub.cms.gov/ede/v1/get-data-matching-issues': 'Get DMIs',
  'https://impl.hub.cms.gov/dsrs/v1/search-metadata': 'Search',
};

const getLabel = (log: TrackingLogFragment): string => {
  if (!log.status || !log.method) return '-';
  return `${log.status} ${log.method?.toUpperCase()}`;
};

const getReadableURL = (url: string) => {
  const [_, endpoint] = url.split('v1');

  if (endpoint) return endpoint;

  const [root2, endpoint2] = url.split('.gov');
  if (endpoint2) return endpoint2;

  return url;
};

export function TrackingLogModal({ personId, isOpen, onRequestClose }: TrackingLogModalProps) {
  const num = 14;

  const { loading, data } = useQuery(GET_TRACKING_LOG, {
    variables: {
      input: {
        personId,
        filter: { created_on_gt_days_ago: num },
      },
    },
  });

  const [selected, setSelected] = useState<TrackingLogFragment>();

  const logs = data?.trackingLog || [];

  return (
    <FullModal
      isOpen={isOpen}
      hasChange={false}
      onRequestClose={onRequestClose}
      title="Tracking Log"
      subtitle={`Last ${num} days`}
      sidebarItem={
        <>
          {logs.map(log => (
            <IRow
              key={log.id}
              label={log.method ? log.method.toUpperCase() : '-'}
              value={getReadableURL(log.url)}
              onPress={() => setSelected(log)}
            />
          ))}
        </>
      }
    >
      {loading ? (
        <div className="full">
          <Spinner loading />
        </div>
      ) : selected ? (
        <div className="tree">
          <RowGroup title="Details">
            <IRow label="ID" type="mono" value={selected?.id} />
            <IRow label="Status" type="mono" value={selected?.status} />
            <IRow label="Method" type="mono" value={selected?.method?.toUpperCase()} />
            <IRow label="URL" value={selected?.url} />
            <IRow label="Created on" type="date" format={DateFormat.FULL} value={selected?.createdOn} />
          </RowGroup>
          {selected?.headers && (
            <RowGroup title="Headers" close>
              <DebugTree data={JSON.parse(selected?.headers)} sort={false} />
            </RowGroup>
          )}
          {selected?.input_payload && (
            <RowGroup title="Input" close>
              <DebugTree data={selected ? JSON.parse(selected?.input_payload) : null} sort={false} />
            </RowGroup>
          )}
          {selected?.output_payload && (
            <RowGroup title="Output" close>
              <DebugTree data={selected ? JSON.parse(selected?.output_payload) : null} sort={false} />
            </RowGroup>
          )}
        </div>
      ) : logs.length === 0 ? (
        <div className="full">No tracking log entries</div>
      ) : (
        <div className="full">Select a log to view more</div>
      )}
    </FullModal>
  );
}
