import React, { useState } from 'react';
import { Popover } from 'antd';
import styled from 'styled-components';

import { initials } from 'src/web/utility';
import { StatusIcon, Icon, FontIcon, Avatar, I, IconType } from 'src/web/components';
import { formatStatus } from 'src/kit';

const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  height: 30px;
  cursor: var(--cursor);
  color: var(--fg);
  opacity: ${p => (p.small ? 0.5 : 1)};
  font-weight: 425;
  width: 100%;
  > .icon-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    margin-top: -1px;
    *:not(.b-size *) {
      color: ${p => p.color} !important;
    }
  }
  > .text-container {
    font-size: ${p => (p.small ? 'var(--ffp)' : 'calc(var(--fp) - 0.5px)')};
    color: var(--fg) !important;
    min-width: 100px;
    padding-right: 24px;
  }
  &:hover {
    background: var(--fga);
    > .text-container {
      color: var(--fg) !important;
    }
  }
  &:active {
    background: var(--cBorder);
    > .text-container {
      color: var(--fg) !important;
    }
  }
`;

export const Menu = ({
  children,
  content,
  placement = 'leftTop',
  trigger = 'click',
  className,
  noDismiss,
}) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = v => {
    setVisible(v);
  };

  return (
    <Popover
      placement={placement}
      trigger={trigger}
      overlayClassName={`popover ${className} `}
      arrowPointAtCenter={false}
      content={<div onClick={() => !noDismiss && setVisible(false)}>{content}</div>}
      open={visible}
      onOpenChange={handleVisibleChange}
    >
      {children}
    </Popover>
  );
};

export const BigTooltip = ({ children, content, placement = 'top', trigger = 'hover', className }) => {
  return (
    <Popover
      placement={placement}
      trigger={trigger}
      overlayClassName={`btooltip ${className} `}
      arrowPointAtCenter={false}
      content={<div>{content}</div>}
    >
      {children}
    </Popover>
  );
};

export interface MenuItemProps {
  icon?: IconType;
  text: React.ReactNode;
  onPress: () => void;
}

export const MenuItem = ({
  small,
  icon,
  text,
  el,
  onPress,
  type,
  fontIcon,
  color,
  task,
  iconOverride,
  statusText,
  fillIcon,
  iconic,
}: MenuItemProps) => {
  return (
    <StyledMenuItem onClick={onPress} color={color} small={small}>
      {(type === 'person' || type === 'status' || !!fontIcon || !!icon || !!iconic) && (
        <div className="icon-container">
          {type === 'person' ? (
            <Avatar image={el?.avatarImage} initials={initials(el)} size={20} />
          ) : type === 'status' ? (
            <StatusIcon status={text} iconOverride={iconOverride} />
          ) : !!fontIcon ? (
            <FontIcon task={task} icon={fontIcon} invert color={color} />
          ) : !!icon ? (
            <Icon logo={icon} containLg={!fillIcon} square size={20} style={{ marginLeft: -4 }} />
          ) : !!iconic ? (
            <I name={iconic} size={18} style={{ margin: 'auto' }} />
          ) : (
            <></>
          )}
        </div>
      )}
      <div className="text-container">{type === 'status' ? formatStatus(statusText || text) : text}</div>
    </StyledMenuItem>
  );
};

export const MenuDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 6px 0;
  background: var(--c-border-light);
`;

export default Menu;
