import { ClosedReasonEnum, CommissionStatus, ConsentType, ConversionStatusEnum, Trinary } from 'src/data';
import { Badge, BadgeType } from './Badge';
import { propercase } from 'src/_utils/format';
import { ConsolidatedStatus } from 'src/types';

export interface StatusProps {
  status: ConsolidatedStatus;
}

export const statusTypes: Record<ConsolidatedStatus, BadgeType> = {
  [ConversionStatusEnum.AccountCreated]: BadgeType.Info,
  [ConversionStatusEnum.PriorPlanYearRenewal]: BadgeType.Info,
  [ConversionStatusEnum.IntakeRequested]: BadgeType.Info,
  [ConversionStatusEnum.IntakeScheduled]: BadgeType.Info,
  [ConversionStatusEnum.IntakeCompleted]: BadgeType.Info,
  [ConversionStatusEnum.PlansSent]: BadgeType.Info,
  [ConversionStatusEnum.PlansChosen]: BadgeType.Info,
  [ConversionStatusEnum.AppStarted]: BadgeType.Info,
  [ConversionStatusEnum.Handoff]: BadgeType.Info,
  [ConversionStatusEnum.OeReady]: BadgeType.Info,
  [ConversionStatusEnum.StateDiy]: BadgeType.Info,
  [ConversionStatusEnum.AppSubmitted]: BadgeType.Good,
  [ConversionStatusEnum.AppEnrolled]: BadgeType.Good,
  [ConversionStatusEnum.Effectuated]: BadgeType.Good,
  [ConversionStatusEnum.Terminated]: BadgeType.Bad,
  [ConversionStatusEnum.Canceled]: BadgeType.Bad,
  [ConversionStatusEnum.GracePeriodDelinquency]: BadgeType.Bad,
  [ConversionStatusEnum.Closed]: BadgeType.Bad,
  [ClosedReasonEnum.Affordability]: BadgeType.Bad,
  [ClosedReasonEnum.Cobra]: BadgeType.Bad,
  [ClosedReasonEnum.EmployerCoverage]: BadgeType.Bad,
  [ClosedReasonEnum.IchraReferral]: BadgeType.Bad,
  [ClosedReasonEnum.IncompleteApplication]: BadgeType.Bad,
  [ClosedReasonEnum.Medicaid]: BadgeType.Bad,
  [ClosedReasonEnum.MedicareReferral]: BadgeType.Bad,
  [ClosedReasonEnum.NotEligibleOther]: BadgeType.Bad,
  [ClosedReasonEnum.NotInterestedAfterIntake]: BadgeType.Bad,
  [ClosedReasonEnum.NoResponseOrIntake]: BadgeType.Bad,
  [ClosedReasonEnum.NoSep]: BadgeType.Bad,
  [ClosedReasonEnum.Other]: BadgeType.Bad,
  [ClosedReasonEnum.OtherReferral]: BadgeType.Bad,
  [ClosedReasonEnum.PeoReferral]: BadgeType.Bad,
  [ConsentType.Broker]: BadgeType.Good,
  [Trinary.Yes]: BadgeType.Good,
  [Trinary.No]: BadgeType.Bad,
  [Trinary.Nil]: BadgeType.Light,
  [CommissionStatus.Unknown]: BadgeType.Mid,
  [CommissionStatus.BinderPaid]: BadgeType.Info,
  [CommissionStatus.CommissionEarned]: BadgeType.Good,
  LOCKED: BadgeType.Bad,
  UNLOCKED: BadgeType.Good,
};

// copy to override the default logic
const specialCopy = {};

const terms = ['DIY', 'COBRA', 'OE', 'ICHRA', 'SEP', 'PEO', 'CIO', 'ID', 'or', 'and'];

const matchTerm = word => {
  for (let i = 0; i < terms.length; i++) {
    if (word.toLowerCase() === terms[i].toLowerCase()) {
      return terms[i];
    }
  }

  return false;
};

export const formatStatus = (val: string): string => {
  if (specialCopy[val]) {
    return specialCopy[val];
  }

  return val
    ?.split('_')
    .map(word => {
      return matchTerm(word) || propercase(word);
    })
    .join(' ');
};

export function Status({ status }: StatusProps) {
  if (!statusTypes[status]) {
    console.warn('Attempting to render status for missing consolidated status:', status);
  }

  return <Badge type={statusTypes[status] || BadgeType.Light} label={formatStatus(status)} />;
}
