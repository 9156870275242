import { gql } from '../generated';

gql(`
  fragment Identity on Identity {
    id
    providerType
    transactionID
    sessionID
    confirmationID
    isTransactionIDExpired
    finalDecisionCode
    status
  }  
`);

export const UPDATE_IDENTITY = gql(`
  mutation UpdateIdentity($input: UpdateIdentityInput!) {
    updateIdentity(input: $input) {
      ...Identity
    }
  }
`);

export const GET_IDENTITY = gql(`
  query IdentityQuery($id: ID) {
    viewer(id: $id) {
      id
      sessionInformation {
        loginHistory {
          deviceType
          loginTimeStamp
          version
        }
      }
    }
    viewerTwo(id: $id) {
      id
      unitIdentity: identity(providerType: UNIT) {
        ...Identity
      }
      edeIdentity: identity(providerType: CMS) {
        ...Identity
      }
      existingApplicationInfo {
        existingApplications {
          id
          familyName
          givenName
          state
          matchesWithAnotherUser
          coverageYearNumber
          applicationStatus
        }
      }
    }
  }
`);
