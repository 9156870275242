import { gql } from '../generated';

gql(`
  fragment TrackingLog on TrackingLog {
    id
    status
    method
    headers
    input_payload
    output_payload
    url
    createdOn
  }
`);

export const GET_TRACKING_LOG = gql(`
  query TrackingLog($input: TrackingLogInput!) {
    trackingLog(input: $input) {
      ...TrackingLog
    }
  }
`);
