import { Env } from 'src/_utils';

interface KAction {
  id: string;
  name: string;
  title: string;
  subtitle?: string;
  pretitle?: string;
  keywords?: string;
  shortcut?: Array<string>;
  section?: 'User' | 'Copy' | 'Health' | 'Funds' | 'Goto' | 'Open' | 'Identity' | 'View';
  icon: any;
  destructive?: boolean;
  hide?: boolean;
}

type ActionType =
  // goto
  | 'GOTO_USER'
  | 'GOTO_ALL'

  // user
  | 'CREATE_USER'
  | 'UPDATE_USER_NAME'
  | 'LOCK_USER'
  | 'UNLOCK_USER'
  | 'CHANGE_TIER'
  | 'DELETE_USER'

  // copy
  | 'COPY_PAGE_LINK'
  | 'COPY_USER_ID'
  | 'COPY_TOKEN'

  // id
  | 'MANUAL_IDP'
  | 'RUN_PLAID_ID'

  // misc
  | 'DEBUG'
  | 'SEARCH'
  | 'DOWNLOAD_FILE'

  // funds
  | 'WITHDRAW_FUNDS'
  | 'TRANSFER_FUNDS_TO_DDA'

  // health
  | 'HEALTH_SYNC'
  | 'HEALTH_IMPORT'
  | 'HEALTH_SUBMIT_APP'
  | 'HEALTH_SUBMIT_ENROLLMENT'
  | 'HEALTH_DELETE_ENROLLMENT'
  | 'HEALTH_ENSURE'
  | 'HEALTH_FETCH_ELIG'
  | 'HEALTH_FETCH_RESUBMIT'
  | 'HEALTH_TRACKING_LOG'

  //view
  | 'HIDE_PII'
  | 'SHOW_PII';

export const KEYS: Record<ActionType, Array<String>> = {};

export const KACTIONS: Record<ActionType, KAction> = {
  HEALTH_SYNC: {
    id: 'sync-health',
    name: 'Refresh Health',
    subtitle: 'EDE',
    keywords: 'refresh, ede, cms, sync, health',
    section: 'Health',
    shortcut: ['h', 'r'],
    icon: 'Refresh',
  },
  HEALTH_IMPORT: {
    id: 'import-all',
    name: 'Lookup & Import Applications',
    subtitle: 'EDE',
    shortcut: ['h', 'l'],
    section: 'Health',
    icon: 'Search',
    keywords: 'lookup, import, app, ede, search',
  },
  CREATE_USER: {
    id: 'create-user',
    name: 'Create User',
    subtitle: 'broker created user',
    keywords: 'create, new, user',
    section: 'User',
    shortcut: ['c', 'u'],
    icon: 'Add',
  },
  DELETE_USER: {
    id: 'user-delete',
    name: 'Close Account',
    shortcut: ['u', 'Backspace'],
    subtitle: 'remove login, funds, DDA',
    keywords: 'disable, delete, user, close',
    section: 'User',
    icon: 'Delete',
    destructive: true,
  },
  CHANGE_TIER: {
    id: 'user-tier',
    name: 'Change Tier',
    subtitle: 'Unit',
    keywords: 'lock, unlock',
    section: 'User',
    shortcut: ['u', 't'],
    icon: 'Tag',
  },
  LOCK_USER: {
    id: 'user-lock',
    name: 'Lock user',
    subtitle: 'Transactions only',
    keywords: 'lock, unlock',
    section: 'User',
    shortcut: ['u', 'l'],
    icon: 'Lock',
    destructive: true,
  },
  UNLOCK_USER: {
    id: 'user-lock',
    name: 'Unlock user',
    keywords: 'lock, unlock',
    section: 'User',
    shortcut: ['u', 'l'],
    icon: 'Unlock',
    destructive: true,
  },
  COPY_PAGE_LINK: {
    id: 'url',
    name: 'Copy page link',
    section: 'Copy',
    shortcut: ['c', 'l'],
    icon: 'Link',
  },
  COPY_USER_ID: {
    id: 'user-id',
    name: 'Copy User ID',
    section: 'Copy',
    shortcut: ['c', 'u', 'i'],
    icon: 'ID',
  },
  COPY_TOKEN: {
    id: 'token',
    name: 'Copy Token',
    section: 'Copy',
    shortcut: ['c', 't'],
    icon: 'ID',
    hide: Env.isProd,
  },
  UPDATE_USER_NAME: {
    id: 'user-update',
    name: `Update User Name`,
    keywords: 'rename, update, user, name, legal, dba, ein, first, last, middle, nickname',
    section: 'User',
    shortcut: ['u', 'n'],
    icon: 'User',
  },
  HEALTH_SUBMIT_APP: {
    id: 'health-submit-app',
    name: 'Submit Application',
    subtitle: 'EDE',
    section: 'Health',
    shortcut: ['h', 'a', 'Enter'],
    icon: 'Coverage',
    keywords: 'submit, save, app, health, ede',
  },
  HEALTH_SUBMIT_ENROLLMENT: {
    id: 'health-submit-enrollment',
    name: 'Submit Enrollment',
    subtitle: 'EDE',
    section: 'Health',
    shortcut: ['h', 'e', 'Enter'],
    icon: 'CoverageCheck',
    keywords: 'submit, enroll, save, app, health, ede',
  },
  HEALTH_DELETE_ENROLLMENT: {
    id: 'health-delete-enrollment',
    name: 'Delete Enrollment',
    section: 'Health',
    icon: 'Delete',
    shortcut: ['h', 'e', 'Backspace'],
    destructive: true,
    keywords: 'delete, save, app, health, ede',
  },
  HEALTH_ENSURE: {
    id: 'health-ensure',
    name: 'Save Application',
    subtitle: 'Ensure to EDE',
    shortcut: ['h', 'a', 's'],
    section: 'Health',
    icon: 'Ensure',
    keywords: 'ensure, save, app, health, ede',
  },
  HEALTH_TRACKING_LOG: {
    id: 'view-tracking-log',
    name: 'Tracking Log',
    icon: 'Code',
    section: 'Health',
  },

  GOTO_USER: {
    id: 'goto-user',
    name: 'User',
    pretitle: 'go to',
    section: 'Goto',
    shortcut: ['g', 'u'],
    keywords: 'user, goto, go to',
    icon: 'User',
  },
  GOTO_ALL: {
    id: 'goto-all',
    name: 'All',
    pretitle: 'go to',
    shortcut: ['g', 'a'],
    icon: 'All',
    keywords: 'all, goto, go to',
    section: 'Goto',
  },
  VIEW_APP: { id: 'view-app', name: 'View Details', icon: 'Shield' },
  DEBUG: { id: 'DEBUG', name: 'Debug', icon: 'Code', shortcut: ['d'] },
  MANUAL_IDP: {
    id: 'manual-idp',
    name: 'Manual IDP',
    subtitle: 'for EDE',
    shortcut: ['i', 'm'],
    icon: 'Fingerprint',
    keywords: 'manual, idp, ede, health, app',
    section: 'Identity',
  },
  RUN_PLAID_ID: {
    id: 'RUN_PLAID_ID',
    name: 'Verify ownership',
    shortcut: ['i', 'p'],
    subtitle: 'via Plaid',
    icon: 'Plaid',
    keywords: 'plaid, verify, identity, ownership, idp, ede, health, app, bank',
    section: 'Identity',
  },
  WITHDRAW_FUNDS: {
    id: 'WITHDRAW_FUNDS',
    destructive: true,
    name: 'Withdraw Funds',
    icon: 'Withdrawal',
    section: 'Funds',
    keywords: 'withdraw, funds, money, cash, bank, account',
    shortcut: ['f', 'w'],
  },
  TRANSFER_FUNDS_TO_DDA: {
    id: 'TRANSFER_FUNDS_TO_DDA',
    destructive: true,
    name: 'Transfer to DDA',
    icon: 'Swap',
    section: 'Funds',
    keywords: 'transfer, funds, money, cash, bank, account, ira, dda, folio, unit',
    shortcut: ['f', 't'],
  },
  DOWNLOAD_FILE: { id: 'DOWNLOAD_FILE', name: 'Download', icon: 'Download' },
  SEARCH: { id: 'SEARCH', name: 'Search users', icon: 'Search', shortcut: ['/'] },
  HEALTH_FETCH_ELIG: {
    id: 'health-fetch-elig',
    name: 'Refetch Eligibility',
    icon: 'Refetch',
    section: 'Health',
    shortcut: ['h', 'a', 'f'],
    keywords: 'health, fetch, eligibility, ede, app',
  },
  HEALTH_FETCH_RESUBMIT: {
    id: 'health-fetch-resubmit',
    name: 'Refetch & Reenroll',
    subtitle: 'for NPN assignment',
    section: 'Health',
    icon: 'Done',
    shortcut: ['h', 'f', 'Enter'],
    keywords: 'health, fetch, re, enroll, submit, npn, assign, eligibility, ede, app',
  },
  COMMAND: {
    id: 'command',
    subtitle: 'more',
    icon: 'Command',
    keywords: 'command, more, menu',
    shortcut: ['⌘', 'k'],
  },
  TOGGLE_PII: {
    id: 'toggle-pii',
    name: 'Toggle PII',
    icon: 'Hide',
    section: 'View',
    keywords: 'hide, privacy, mode, pii',
    shortcut: ['p'],
  },
};
