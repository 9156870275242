import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@apollo/client';
import { MANUAL_IDENTITY } from 'src/web/data/mutations/ede';
import { UPLOADS } from 'src/web/data';
import {
  Button,
  StyledBottomBar,
  InputLabel,
  Input,
  UploadTile,
  Spinner,
  FullModal,
  RowGroup,
  IRow,
  Toolbar,
  ToolbarButton,
} from 'src/web/components';
import { GET_IDENTITY } from 'src/data';

const StyledTile = styled.div`
  position: absolute;
  right: 24px;
  left: 24px;
  bottom: 200px;
  top: calc(24px + var(--navHeight));
  opacity: 1;
  background: transparent url(${p => p.src}) no-repeat center/contain;
  height: auto;
  width: auto;
  filter: drop-shadow(0px 5px 20px rgba(6, 7, 10, 0.15));
  cursor: pointer;
  transform: rotate(${p => p.rotation}deg);
  will-change: transform;
  transition: var(--transition);
  div {
    will-change: transform;
    transform: rotate(${p => -p.rotation}deg);
    transition: var(--transition);
  }
`;

export const ManualIdpModal = ({ userId, onRequestClose, task, info, appId }) => {
  const [selected, setSelected] = useState();
  const [docNum, setDocNum] = useState();
  const [rotation, setRotation] = useState(0);

  const { data, loading, error } = useQuery(UPLOADS, {
    skip: !userId,
    variables: {
      id: userId,
    },
  });

  const [submit, { loading: updating }] = useMutation(MANUAL_IDENTITY, {
    onError: e => {
      console.log(e);
    },
    onCompleted: onRequestClose,
    refetchQueries: [
      'ManualIdQuery',
      'BadgeNumQuery',
      'HealthEnrollments',
      { query: GET_IDENTITY, variables: { id: userId } },
    ],
    variables: {
      input: {
        personID: userId,
        documentID: selected?.id,
        documentNumber: docNum,
      },
    },
  });

  const docs = data?.viewerTwo?.documentUploads?.filter(doc =>
    /PASSPORT|PHOTO_ID_FRONT|SOCIAL_SECURITY_CARD/.test(doc?.documentType),
  );

  return (
    <FullModal
      hasChange={selected?.id || docNum}
      task={task}
      onRequestClose={onRequestClose}
      title="Manual ID Proof"
      subtitle="EDE"
      sidebarItem={
        <>
          <RowGroup title="Information">
            <IRow pii label="First" value={info?.givenName} />
            <IRow pii label="Last" value={info?.familyName} />
            <IRow pii label="State" value={info?.coverageState} />
            <IRow pii label="SSN" mono value={info?.ssn} />
            <IRow pii label="DOB" mono type="dateOnly" value={info?.dob} />
          </RowGroup>
          <RowGroup title="Uploads">
            {docs?.length > 0 ? (
              docs?.map((item, idx) => (
                <UploadTile
                  key={item.id}
                  id={item.id}
                  createdOn={item.createdOn}
                  name={item.documentType}
                  selected={selected?.id === item?.id}
                  imageUrl={item.url}
                  onPress={() => setSelected(item)}
                />
              ))
            ) : (
              <div className="full">{loading ? <Spinner /> : error ? 'Error' : 'No uploads'}</div>
            )}
          </RowGroup>
        </>
      }
    >
      <Toolbar
        hideDefaultActions
        borderless
        pageActions={[
          {
            icon: 'RotateLeft',
            tooltip: 'Rotate left',
            onPress: () => setRotation(rotation - 90),
            disabled: !selected,
          },
          {
            icon: 'RotateRight',
            tooltip: 'Rotate right',
            onPress: () => setRotation(rotation + 90),
            disabled: !selected,
          },
          {
            icon: 'FileDownload',
            tooltip: 'Download',
            onPress: () => window.open(selected?.url, '_blank'),
            disabled: !selected,
          },
        ]}
      />
      <StyledTile rotation={rotation} className="full" src={selected?.url}></StyledTile>

      <StyledBottomBar>
        <div style={{ marginBottom: 12 }}>
          <InputLabel required>Document Number</InputLabel>
          <Input
            className="mono"
            onChange={event => setDocNum(event.target.value)}
            type="text"
            placeholder="XXX XXX XXX XXX"
            value={docNum}
            onKeyUp={e => {
              if (e.key === 'Enter') submit();
            }}
          />
        </div>
        <Button disabled={updating || !userId || !selected?.id || !docNum} loading={loading} onPress={submit}>
          Approve & Submit
        </Button>
      </StyledBottomBar>
    </FullModal>
  );
};

export default ManualIdpModal;
